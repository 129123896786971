<template>
     <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
         <el-form ref="form" label-width="130px">
             <el-form-item size="small" label="上级名称:" required>
                 <p v-text="editData.parentName"></p>
             </el-form-item>
             <el-form-item size="small" label="机构代码:" required>
                 <p v-text="editData.departmentCode"></p>
             </el-form-item>
             <el-form-item size="small" label="机构名称:" required >
                 <el-input v-model="editData.departmentName" style="width: 400px;" maxlength="6" show-word-limit></el-input>
             </el-form-item>
           <el-form-item label="短信数量:" required >
             <el-input size="small"  v-model="editData.messageNum"  onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="10"  style="width: 400px;" show-word-limit></el-input>
           </el-form-item>
           <el-form-item label="到期时间:" required >
             <div class="block">
               <el-date-picker
                 style="width: 400px"
                 size="small"
                 v-model="editData.mdEndTime"
                 type="date"
                 placeholder="选择日期">
               </el-date-picker>
             </div>
           </el-form-item>
           <el-form-item label="是否开通小程序:" required >
             <el-radio-group v-model="editData.wxStatus">
               <el-radio :label="'0'">否</el-radio>
               <el-radio :label="'1'">是</el-radio>
             </el-radio-group>
           </el-form-item>
           <el-form-item label="小程序到期时间:" required  >
             <div class="block">
               <el-date-picker
                 style="width: 400px"
                 size="small"
                 v-model="editData.wxEndTime"
                 type="date"
                 placeholder="选择日期">
               </el-date-picker>
             </div>
           </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="updateDepartment">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
     </el-dialog>
 </template>
<script>
  import {updateDepartment} from '../../../api/sys/department'

  export default {
    data () {
      return {
        show: false,
      }
    },

    props: {
      editData: Object,
      editVisible: Boolean
    },
    methods: {
      closeEvent () {
        this.$emit('closeEdit')
      },
      async updateDepartment () {
        let data = {
            id: this.editData.id,
            departmentName: this.editData.departmentName,
            messageNum: this.editData.messageNum,
            wxStatus: this.editData.wxStatus,
        };
        if (this.editData.wxEndTime){
          data['wxEndTime']= new Date(this.editData.wxEndTime)
        }
        if (this.editData.mdEndTime){
          data['mdEndTime']=new Date(this.editData.mdEndTime)
        }
        let res = await updateDepartment(data);
        if (res.code==200){
              this.$message({
                showClose: true,
                message: res.message,
                type: 'success'
              });
              this.$emit('editSuccess')
          }else{
              this.$message({
                showClose: true,
                message: res.message,
                type: 'error'
              });
          }
      }
    }
  }
</script>

<style scoped>

</style>
